/*globals qq, document, CustomEvent*/
qq.DragAndDrop = function(o) {
    "use strict";

    var options,
        HIDE_ZONES_EVENT_NAME = "qq-hidezones",
        HIDE_BEFORE_ENTER_ATTR = "qq-hide-dropzone",
        uploadDropZones = [],
        droppedFiles = [],
        disposeSupport = new qq.DisposeSupport();

    options = {
        dropZoneElements: [],
        allowMultipleItems: true,
        classes: {
            dropActive: null
        },
        callbacks: new qq.DragAndDrop.callbacks()
    };

    qq.extend(options, o, true);

    function uploadDroppedFiles(files, uploadDropZone) {
        // We need to convert the `FileList` to an actual `Array` to avoid iteration issues
        var filesAsArray = Array.prototype.slice.call(files);

        options.callbacks.dropLog("Grabbed " + files.length + " dropped files.");
        uploadDropZone.dropDisabled(false);
        options.callbacks.processingDroppedFilesComplete(filesAsArray, uploadDropZone.getElement());
    }

    function traverseFileTree(entry) {
        var parseEntryPromise = new qq.Promise();

        if (entry.isFile) {
            entry.file(function(file) {
                file.qqPath = extractDirectoryPath(entry);
                droppedFiles.push(file);
                parseEntryPromise.success();
            },
            function(fileError) {
                options.callbacks.dropLog("Problem parsing '" + entry.fullPath + "'.  FileError code " + fileError.code + ".", "error");
                parseEntryPromise.failure();
            });
        }
        else if (entry.isDirectory) {
            getFilesInDirectory(entry).then(
                function allEntriesRead(entries) {
                    var entriesLeft = entries.length;

                    qq.each(entries, function(idx, entry) {
                        traverseFileTree(entry).done(function() {
                            entriesLeft -= 1;

                            if (entriesLeft === 0) {
                                parseEntryPromise.success();
                            }
                        });
                    });

                    if (!entries.length) {
                        parseEntryPromise.success();
                    }
                },

                function readFailure(fileError) {
                    options.callbacks.dropLog("Problem parsing '" + entry.fullPath + "'.  FileError code " + fileError.code + ".", "error");
                    parseEntryPromise.failure();
                }
            );
        }

        return parseEntryPromise;
    }

    function extractDirectoryPath(entry) {
        var name = entry.name,
            fullPath = entry.fullPath,
            indexOfNameInFullPath = fullPath.lastIndexOf(name);

        // remove file name from full path string
        fullPath = fullPath.substr(0, indexOfNameInFullPath);

        // remove leading slash in full path string
        if (fullPath.charAt(0) === "/") {
            fullPath = fullPath.substr(1);
        }

        return fullPath;
    }

    // Promissory.  Guaranteed to read all files in the root of the passed directory.
    function getFilesInDirectory(entry, reader, accumEntries, existingPromise) {
        var promise = existingPromise || new qq.Promise(),
            dirReader = reader || entry.createReader();

        dirReader.readEntries(
            function readSuccess(entries) {
                var newEntries = accumEntries ? accumEntries.concat(entries) : entries;

                if (entries.length) {
                    setTimeout(function() { // prevent stack overflow, however unlikely
                        getFilesInDirectory(entry, dirReader, newEntries, promise);
                    }, 0);
                }
                else {
                    promise.success(newEntries);
                }
            },

            promise.failure
        );

        return promise;
    }

    function handleDataTransfer(dataTransfer, uploadDropZone) {
        var pendingFolderPromises = [],
            handleDataTransferPromise = new qq.Promise();

        options.callbacks.processingDroppedFiles();
        uploadDropZone.dropDisabled(true);

        if (dataTransfer.files.length > 1 && !options.allowMultipleItems) {
            options.callbacks.processingDroppedFilesComplete([]);
            options.callbacks.dropError("tooManyFilesError", "");
            uploadDropZone.dropDisabled(false);
            handleDataTransferPromise.failure();
        }
        else {
            droppedFiles = [];

            if (qq.isFolderDropSupported(dataTransfer)) {
                qq.each(dataTransfer.items, function(idx, item) {
                    var entry = item.webkitGetAsEntry();

                    if (entry) {
                        //due to a bug in Chrome's File System API impl - #149735
                        if (entry.isFile) {
                            droppedFiles.push(item.getAsFile());
                        }

                        else {
                            pendingFolderPromises.push(traverseFileTree(entry).done(function() {
                                pendingFolderPromises.pop();
                                if (pendingFolderPromises.length === 0) {
                                    handleDataTransferPromise.success();
                                }
                            }));
                        }
                    }
                });
            }
            else {
                droppedFiles = dataTransfer.files;
            }

            if (pendingFolderPromises.length === 0) {
                handleDataTransferPromise.success();
            }
        }

        return handleDataTransferPromise;
    }

    function setupDropzone(dropArea) {
        var dropZone = new qq.UploadDropZone({
            HIDE_ZONES_EVENT_NAME: HIDE_ZONES_EVENT_NAME,
            element: dropArea,
            onEnter: function(e) {
                qq(dropArea).addClass(options.classes.dropActive);
                e.stopPropagation();
            },
            onLeaveNotDescendants: function(e) {
                qq(dropArea).removeClass(options.classes.dropActive);
            },
            onDrop: function(e) {
                handleDataTransfer(e.dataTransfer, dropZone).then(
                    function() {
                        uploadDroppedFiles(droppedFiles, dropZone);
                    },
                    function() {
                        options.callbacks.dropLog("Drop event DataTransfer parsing failed.  No files will be uploaded.", "error");
                    }
                );
            }
        });

        disposeSupport.addDisposer(function() {
            dropZone.dispose();
        });

        qq(dropArea).hasAttribute(HIDE_BEFORE_ENTER_ATTR) && qq(dropArea).hide();

        uploadDropZones.push(dropZone);

        return dropZone;
    }

    function isFileDrag(dragEvent) {
        var fileDrag;

        qq.each(dragEvent.dataTransfer.types, function(key, val) {
            if (val === "Files") {
                fileDrag = true;
                return false;
            }
        });

        return fileDrag;
    }

    // Attempt to determine when the file has left the document.  It is not always possible to detect this
    // in all cases, but it is generally possible in all browsers, with a few exceptions.
    //
    // Exceptions:
    // * IE10+ & Safari: We can't detect a file leaving the document if the Explorer window housing the file
    //                   overlays the browser window.
    // * IE10+: If the file is dragged out of the window too quickly, IE does not set the expected values of the
    //          event's X & Y properties.
    function leavingDocumentOut(e) {
        if (qq.safari()) {
            return e.x < 0 || e.y < 0;
        }

        return e.x === 0 && e.y === 0;
    }

    function setupDragDrop() {
        var dropZones = options.dropZoneElements,

            maybeHideDropZones = function() {
                setTimeout(function() {
                    qq.each(dropZones, function(idx, dropZone) {
                        qq(dropZone).hasAttribute(HIDE_BEFORE_ENTER_ATTR) && qq(dropZone).hide();
                        qq(dropZone).removeClass(options.classes.dropActive);
                    });
                }, 10);
            };

        qq.each(dropZones, function(idx, dropZone) {
            var uploadDropZone = setupDropzone(dropZone);

            // IE <= 9 does not support the File API used for drag+drop uploads
            if (dropZones.length && qq.supportedFeatures.fileDrop) {
                disposeSupport.attach(document, "dragenter", function(e) {
                    if (!uploadDropZone.dropDisabled() && isFileDrag(e)) {
                        qq.each(dropZones, function(idx, dropZone) {
                            // We can't apply styles to non-HTMLElements, since they lack the `style` property.
                            // Also, if the drop zone isn't initially hidden, let's not mess with `style.display`.
                            if (dropZone instanceof HTMLElement &&
                                qq(dropZone).hasAttribute(HIDE_BEFORE_ENTER_ATTR)) {

                                qq(dropZone).css({display: "block"});
                            }
                        });
                    }
                });
            }
        });

        disposeSupport.attach(document, "dragleave", function(e) {
            if (leavingDocumentOut(e)) {
                maybeHideDropZones();
            }
        });

        // Just in case we were not able to detect when a dragged file has left the document,
        // hide all relevant drop zones the next time the mouse enters the document.
        // Note that mouse events such as this one are not fired during drag operations.
        disposeSupport.attach(qq(document).children()[0], "mouseenter", function(e) {
            maybeHideDropZones();
        });

        disposeSupport.attach(document, "drop", function(e) {
            if (isFileDrag(e)) {
                e.preventDefault();
                maybeHideDropZones();
            }
        });

        disposeSupport.attach(document, HIDE_ZONES_EVENT_NAME, maybeHideDropZones);
    }

    setupDragDrop();

    qq.extend(this, {
        setupExtraDropzone: function(element) {
            options.dropZoneElements.push(element);
            setupDropzone(element);
        },

        removeDropzone: function(element) {
            var i,
                dzs = options.dropZoneElements;

            for (i in dzs) {
                if (dzs[i] === element) {
                    return dzs.splice(i, 1);
                }
            }
        },

        dispose: function() {
            disposeSupport.dispose();
            qq.each(uploadDropZones, function(idx, dropZone) {
                dropZone.dispose();
            });
        }
    });

    this._testing = {};
    this._testing.extractDirectoryPath = extractDirectoryPath;
};

qq.DragAndDrop.callbacks = function() {
    "use strict";

    return {
        processingDroppedFiles: function() {},
        processingDroppedFilesComplete: function(files, targetEl) {},
        dropError: function(code, errorSpecifics) {
            qq.log("Drag & drop error code '" + code + " with these specifics: '" + errorSpecifics + "'", "error");
        },
        dropLog: function(message, level) {
            qq.log(message, level);
        }
    };
};

qq.UploadDropZone = function(o) {
    "use strict";

    var disposeSupport = new qq.DisposeSupport(),
        options, element, preventDrop, dropOutsideDisabled;

    options = {
        element: null,
        onEnter: function(e) {},
        onLeave: function(e) {},
        // is not fired when leaving element by hovering descendants
        onLeaveNotDescendants: function(e) {},
        onDrop: function(e) {}
    };

    qq.extend(options, o);
    element = options.element;

    function dragoverShouldBeCanceled() {
        return qq.safari() || (qq.firefox() && qq.windows());
    }

    function disableDropOutside(e) {
        // run only once for all instances
        if (!dropOutsideDisabled) {

            // for these cases we need to catch onDrop to reset dropArea
            if (dragoverShouldBeCanceled) {
                disposeSupport.attach(document, "dragover", function(e) {
                    e.preventDefault();
                });
            } else {
                disposeSupport.attach(document, "dragover", function(e) {
                    if (e.dataTransfer) {
                        e.dataTransfer.dropEffect = "none";
                        e.preventDefault();
                    }
                });
            }

            dropOutsideDisabled = true;
        }
    }

    function isValidFileDrag(e) {
        // e.dataTransfer currently causing IE errors
        // IE9 does NOT support file API, so drag-and-drop is not possible
        if (!qq.supportedFeatures.fileDrop) {
            return false;
        }

        var effectTest, dt = e.dataTransfer,
        // do not check dt.types.contains in webkit, because it crashes safari 4
        isSafari = qq.safari();

        // dt.effectAllowed is none in Safari 5

        // dt.effectAllowed crashes IE 11 & 10 when files have been dragged from
        // the filesystem
        effectTest = qq.ie() && qq.supportedFeatures.fileDrop ? true : dt.effectAllowed !== "none";
        return dt && effectTest &&
                (
                    (dt.files && dt.files.length) ||                                     // Valid for drop events with files
                    (!isSafari && dt.types.contains && dt.types.contains("Files")) ||  // Valid in Chrome/Firefox
                    (dt.types.includes && dt.types.includes("Files"))               // Valid in IE
                );
    }

    function isOrSetDropDisabled(isDisabled) {
        if (isDisabled !== undefined) {
            preventDrop = isDisabled;
        }
        return preventDrop;
    }

    function triggerHidezonesEvent() {
        var hideZonesEvent;

        function triggerUsingOldApi() {
            hideZonesEvent = document.createEvent("Event");
            hideZonesEvent.initEvent(options.HIDE_ZONES_EVENT_NAME, true, true);
        }

        if (window.CustomEvent) {
            try {
                hideZonesEvent = new CustomEvent(options.HIDE_ZONES_EVENT_NAME);
            }
            catch (err) {
                triggerUsingOldApi();
            }
        }
        else {
            triggerUsingOldApi();
        }

        document.dispatchEvent(hideZonesEvent);
    }

    function attachEvents() {
        disposeSupport.attach(element, "dragover", function(e) {
            if (!isValidFileDrag(e)) {
                return;
            }

            // dt.effectAllowed crashes IE 11 & 10 when files have been dragged from
            // the filesystem
            var effect = qq.ie() && qq.supportedFeatures.fileDrop ? null : e.dataTransfer.effectAllowed;
            if (effect === "move" || effect === "linkMove") {
                e.dataTransfer.dropEffect = "move"; // for FF (only move allowed)
            } else {
                e.dataTransfer.dropEffect = "copy"; // for Chrome
            }

            e.stopPropagation();
            e.preventDefault();
        });

        disposeSupport.attach(element, "dragenter", function(e) {
            if (!isOrSetDropDisabled()) {
                if (!isValidFileDrag(e)) {
                    return;
                }
                options.onEnter(e);
            }
        });

        disposeSupport.attach(element, "dragleave", function(e) {
            if (!isValidFileDrag(e)) {
                return;
            }

            options.onLeave(e);

            var relatedTarget = document.elementFromPoint(e.clientX, e.clientY);
            // do not fire when moving a mouse over a descendant
            if (qq(this).contains(relatedTarget)) {
                return;
            }

            options.onLeaveNotDescendants(e);
        });

        disposeSupport.attach(element, "drop", function(e) {
            if (!isOrSetDropDisabled()) {
                if (!isValidFileDrag(e)) {
                    return;
                }

                e.preventDefault();
                e.stopPropagation();
                options.onDrop(e);

                triggerHidezonesEvent();
            }
        });
    }

    disableDropOutside();
    attachEvents();

    qq.extend(this, {
        dropDisabled: function(isDisabled) {
            return isOrSetDropDisabled(isDisabled);
        },

        dispose: function() {
            disposeSupport.dispose();
        },

        getElement: function() {
            return element;
        }
    });

    this._testing = {};
    this._testing.isValidFileDrag = isValidFileDrag;
};
